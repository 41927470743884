import { OrderAdvancedSearchForm, OrderBonusPayments } from './types';

export const defaultOrderAdvancedSearchForm: OrderAdvancedSearchForm = {
  customerId: '',
  dateFrom: null,
  dateTo: null,
  driverId: '',
  isInvoiced: false,
  paymentStatus: '',
  status: '',
};

export const defaultOrderBonusPayments: OrderBonusPayments = {
  driverBonusPayment: 0,
  helperBonusPayment: 0,
};
