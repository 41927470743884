import * as yup from 'yup';

export const VALIDATION_SCHEMA = yup.object({
  password: yup
    .string()
    .trim('Password cannot include spaces')
    .strict()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  passwordConfirmation: yup
    .string()
    .trim('Confirm password cannot include spaces')
    .strict()
    .required('Confirm password is required')
    .min(8, 'Confirm password must be at least 8 characters')
    .test('passwords-match', 'Passwords must match', (value, context) => context.parent.password === value),
});
