import { Button, FormItem, Input } from 'components';
import { useResetPasswordPage } from './hooks';

export function ResetPasswordPage() {
  const { register, onSubmit, searchParams, formState } = useResetPasswordPage();

  return (
    <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="w-auto h-32 mx-auto" src="/assets/images/logo.png" alt="logo" />
        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">Please set your new password</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
          {searchParams.has('code' && 'email') ? (
            <form className="space-y-6" onSubmit={onSubmit}>
              <FormItem label="New Password" htmlFor="password" error={formState.errors.password?.message}>
                <Input {...register('password')} id="password" type="password" autoComplete="password" required />
              </FormItem>

              <FormItem
                label="Confirm Password"
                htmlFor="passwordConfirmation"
                error={formState.errors.passwordConfirmation?.message}
              >
                <Input {...register('passwordConfirmation')} id="passwordConfirmation" type="password" />
              </FormItem>

              <div>
                <Button fullWidth type="submit" label="Create password" />
              </div>
            </form>
          ) : (
            <p className="space-y-6 text-red-500 text-center">Invalid reset password code</p>
          )}
        </div>
      </div>
    </div>
  );
}
