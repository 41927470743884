import * as yup from 'yup';
import { FormState, UseFormRegister } from 'react-hook-form';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { CellProps } from 'react-table';
import { TrashIcon } from '@heroicons/react/outline';

import { ItemPickupTime, ItemPickupTimes } from 'services/orders';
import { TableColumn, Input } from 'components';

import { BulkOrder, BulkOrderForm } from '../types';

export const itemPickupTimeRegex = /^([1-9]|1[0-2]):[0-5][0-9] (AM|PM) - ([1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;

export const IMPORT_VALIDATION_SCHEMA = yup.object().shape({
  merchantId: yup.string().typeError('Merchant is required').required('Merchant is required'),
  orders: yup.array().of(
    yup.object().shape({
      pickupName: yup.string().required('Pickup name is required'),
      pickupPhone: yup.string().required('Pickup phone is required'),
      pickupAddress1: yup.string().required('Pickup address is required'),
      pickupCity: yup.string().required('Pickup city is required'),
      pickupState: yup.string().required('Pickup state is required'),
      pickupZip: yup.string().required('Pickup zip is required'),
      deliveryName: yup.string().required('Delivery name is required'),
      deliveryPhone: yup.string().required('Delivery phone is required'),
      deliveryAddress1: yup.string().required('Delivery address is required'),
      deliveryCity: yup.string().required('Delivery city is required'),
      deliveryState: yup.string().required('Delivery state is required'),
      deliveryZip: yup.string().required('Delivery zip is required'),
      numOfLoadHelpers: yup
        .number()
        .typeError('Number of load helpers is required')
        .required('Number of load helpers is required')
        .min(0, 'Number of load helpers must be at least 0')
        .max(2, 'Number of load helpers must be at most 2'),
      numOfUnloadHelpers: yup
        .number()
        .typeError('Number of load helpers is required')
        .required('Number of unload helpers is required')
        .min(0, 'Number of unload helpers must be at least 0')
        .max(2, 'Number of unload helpers must be at most 2'),

      itemPickupDate: yup
        .string()
        .required('Pickup date is required')
        .test(
          'item-pickup-date',
          "Pickup date can only be today's date or a future date and the format should be: e.g. 03/13/2023",
          (value) => {
            const validFormat = dayjs(value, 'MM/DD/YYYY', true).isValid();
            const date = new Date(value!);

            return (
              validFormat &&
              (dayjs(date).isSame(dayjs(new Date()), 'date') || dayjs(date).isAfter(dayjs(new Date()), 'date'))
            );
          },
        ),
      itemPickupTime: yup
        .string()
        .matches(itemPickupTimeRegex, 'Wrong Item Pickup Time value')
        .required('Pickup time is required'),
      deliveryTypeId: yup.string().required('Delivery type is required'),
      itemDetails: yup.string().required('Item details is required'),
      merchantOrderNumber: yup.string().required('Merchant order number is required'),
    }),
  ),
});

export const columns = (
  formState: FormState<BulkOrderForm>,
  driverGroups: { label: string; value: string }[],
  selectedMerchantItemPickupTimes: ItemPickupTimes[],
  register: UseFormRegister<BulkOrderForm>,
  handleRowDelete: (index: number) => void,
): TableColumn<BulkOrder>[] => [
  {
    Header: '#',
    Cell: ({ row }: CellProps<BulkOrder>) => <span>{row.index + 1}</span>,
  },
  {
    Header: ' ',
    Cell: ({ row }: CellProps<BulkOrder>) => (
      <button type="button" className="block m-auto" onClick={() => handleRowDelete(row.index)}>
        <TrashIcon className="h-5" />
      </button>
    ),
  },
  {
    Header: 'Pickup Name',
    accessor: 'pickupName',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Pickup name"
          type="text"
          {...register(`orders.${index}.pickupName`)}
          error={!!formState.errors.orders?.[index]?.pickupName?.message}
        />
      );
    },
  },
  {
    Header: 'Pickup Phone',
    accessor: 'pickupPhone',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Pickup phone"
          type="text"
          {...register(`orders.${index}.pickupPhone`)}
          error={!!formState.errors.orders?.[index]?.pickupPhone?.message}
        />
      );
    },
  },
  {
    Header: 'Pickup Address 1',
    accessor: 'pickupAddress1',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-40"
          placeholder="Pickup address 1"
          type="text"
          {...register(`orders.${index}.pickupAddress1`)}
          error={!!formState.errors.orders?.[index]?.pickupAddress1?.message}
        />
      );
    },
  },
  {
    Header: 'Pickup Address 2',
    accessor: 'pickupAddress2',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-40"
          placeholder="Pickup address 2"
          type="text"
          {...register(`orders.${index}.pickupAddress2`)}
        />
      );
    },
  },
  {
    Header: 'Pickup City',
    accessor: 'pickupCity',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Pickup city"
          type="text"
          {...register(`orders.${index}.pickupCity`)}
          error={!!formState.errors.orders?.[index]?.pickupCity?.message}
        />
      );
    },
  },
  {
    Header: 'Pickup State',
    accessor: 'pickupState',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Pickup state"
          type="text"
          {...register(`orders.${index}.pickupState`)}
          error={!!formState.errors.orders?.[index]?.pickupState?.message}
        />
      );
    },
  },
  {
    Header: 'Pickup Zip',
    accessor: 'pickupZip',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Pickup zip"
          type="text"
          {...register(`orders.${index}.pickupZip`)}
          error={!!formState.errors.orders?.[index]?.pickupZip?.message}
        />
      );
    },
  },
  {
    Header: 'Pickup Note',
    accessor: 'pickupNote',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input className="w-32" placeholder="Pickup note" type="text" {...register(`orders.${index}.pickupNote`)} />
      );
    },
  },
  {
    Header: 'Delivery Name',
    accessor: 'deliveryName',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Delivery name"
          type="text"
          {...register(`orders.${index}.deliveryName`)}
          error={!!formState.errors.orders?.[index]?.deliveryName?.message}
        />
      );
    },
  },
  {
    Header: 'Delivery Phone',
    accessor: 'deliveryPhone',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Delivery phone"
          type="text"
          {...register(`orders.${index}.deliveryPhone`)}
          error={!!formState.errors.orders?.[index]?.deliveryPhone?.message}
        />
      );
    },
  },
  {
    Header: 'Delivery Address 1',
    accessor: 'deliveryAddress1',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-40"
          placeholder="Delivery address 1"
          type="text"
          {...register(`orders.${index}.deliveryAddress1`)}
          error={!!formState.errors.orders?.[index]?.deliveryAddress1?.message}
        />
      );
    },
  },
  {
    Header: 'Delivery Address 2',
    accessor: 'deliveryAddress2',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-40"
          placeholder="Delivery address 2"
          type="text"
          {...register(`orders.${index}.deliveryAddress2`)}
        />
      );
    },
  },
  {
    Header: 'Delivery City',
    accessor: 'deliveryCity',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Delivery city"
          type="text"
          {...register(`orders.${index}.deliveryCity`)}
          error={!!formState.errors.orders?.[index]?.deliveryCity?.message}
        />
      );
    },
  },
  {
    Header: 'Delivery State',
    accessor: 'deliveryState',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Delivery state"
          type="text"
          {...register(`orders.${index}.deliveryState`)}
          error={!!formState.errors.orders?.[index]?.deliveryState?.message}
        />
      );
    },
  },
  {
    Header: 'Delivery Zip',
    accessor: 'deliveryZip',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Delivery zip"
          type="text"
          {...register(`orders.${index}.deliveryZip`)}
          error={!!formState.errors.orders?.[index]?.deliveryZip?.message}
        />
      );
    },
  },
  {
    Header: 'Delivery Note',
    accessor: 'deliveryNote',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input className="w-32" placeholder="Delivery note" type="text" {...register(`orders.${index}.deliveryNote`)} />
      );
    },
  },
  {
    Header: 'Item Pickup Date',
    accessor: 'itemPickupDate',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-40"
          placeholder="MM/DD/YYYY"
          type="text"
          {...register(`orders.${index}.itemPickupDate`)}
          error={!!formState.errors.orders?.[index]?.itemPickupDate?.message}
        />
      );
    },
  },
  {
    Header: 'Item Pickup Time',
    accessor: 'itemPickupTime',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <div className="w-48">
          <select
            className={clsx(
              'relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm h-9',
              {
                'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500':
                  !formState.errors.orders?.[index]?.itemPickupTime?.message,
                'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500':
                  !!formState.errors.orders?.[index]?.itemPickupTime?.message,
              },
            )}
            {...register(`orders.${index}.itemPickupTime`)}
          >
            <option disabled value="">
              Select an option
            </option>
            {selectedMerchantItemPickupTimes.length
              ? selectedMerchantItemPickupTimes.map((time) => (
                  <option key={time.name} value={`${time.pickupTimeFrom} - ${time.pickupTimeTo}`}>
                    {`${time.pickupTimeFrom} - ${time.pickupTimeTo}`}
                  </option>
                ))
              : Object.values(ItemPickupTime).map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
          </select>
        </div>
      );
    },
  },
  {
    Header: 'Load Helpers (#)',
    accessor: 'numOfLoadHelpers',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-36"
          placeholder="Number of load helpers"
          type="number"
          {...register(`orders.${index}.numOfLoadHelpers`)}
          error={!!formState.errors.orders?.[index]?.numOfLoadHelpers?.message}
        />
      );
    },
  },
  {
    Header: 'Unload Helpers (#)',
    accessor: 'numOfUnloadHelpers',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-36"
          placeholder="Number of unload helpers"
          type="number"
          {...register(`orders.${index}.numOfUnloadHelpers`)}
          error={!!formState.errors.orders?.[index]?.numOfUnloadHelpers?.message}
        />
      );
    },
  },
  {
    Header: 'Delivery Type ID',
    accessor: 'deliveryTypeId',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <div className="w-44">
          <select
            className={clsx(
              'relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm h-9',
              {
                'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500':
                  !formState.errors.orders?.[index]?.deliveryTypeId?.message,
                'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500':
                  !!formState.errors.orders?.[index]?.deliveryTypeId?.message,
              },
            )}
            {...register(`orders.${index}.deliveryTypeId`)}
          >
            {driverGroups.map((deliveryType) => (
              <option key={deliveryType.value} value={deliveryType.value}>
                {deliveryType.label}
              </option>
            ))}
          </select>
        </div>
      );
    },
  },
  {
    Header: 'Item Details',
    accessor: 'itemDetails',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-32"
          placeholder="Item details"
          type="text"
          {...register(`orders.${index}.itemDetails`)}
          error={!!formState.errors.orders?.[index]?.itemDetails?.message}
        />
      );
    },
  },
  {
    Header: 'Merchant Order Number',
    accessor: 'merchantOrderNumber',
    Cell: ({ row }: CellProps<BulkOrder>) => {
      const { index } = row;

      return (
        <Input
          className="w-48"
          placeholder="Merchant order number"
          type="text"
          {...register(`orders.${index}.merchantOrderNumber`)}
          error={!!formState.errors.orders?.[index]?.merchantOrderNumber?.message}
        />
      );
    },
  },
];
