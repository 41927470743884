import { FlagIcon, HomeIcon, UploadIcon } from '@heroicons/react/outline';

import { NavigationItem } from '../MenuItem';

export const NAVIGATION: NavigationItem[] = [
  {
    to: '/',
    name: 'Dashboard',
    icon: HomeIcon,
  },
  // {
  //   to: '/driver-groups',
  //   name: 'Driver groups',
  //   icon: TruckIcon,
  // },
  // {
  //   to: '/merchants',
  //   name: 'Merchants',
  //   icon: BriefcaseIcon,
  // },
  // {
  //   to: '/promo-codes',
  //   name: 'Promo Codes',
  //   icon: TagIcon,
  // },
  {
    to: '/orders',
    name: 'Orders',
    icon: FlagIcon,
  },
  /*   {
    to: '/chat',
    name: 'Support Chat',
    icon: ChatIcon,
  }, */
  // {
  //   to: '/driver-payments',
  //   name: 'Driver Payments',
  //   icon: CashIcon,
  // },
  // {
  //   to: '/reviews',
  //   name: 'Driver Reviews',
  //   icon: StarIcon,
  // },
  {
    to: '/upload',
    name: 'Upload CSV',
    icon: UploadIcon,
  },
  // {
  //   to: '/notification',
  //   name: 'SMS Notification',
  //   icon: PhoneIcon,
  // },
  // {
  //   to: '',
  //   name: 'User Management',
  //   icon: UserIcon,
  //   subMenu: [
  //     {
  //       to: '/drivers',
  //       name: 'Drivers',
  //       icon: TruckIcon,
  //     },
  //     {
  //       to: '/customers',
  //       name: 'Customers',
  //       icon: UserIcon,
  //     },
  //     {
  //       to: '/users',
  //       name: 'Users',
  //       icon: UserIcon,
  //     },
  //   ],
  // },
  // {
  //   to: '',
  //   name: 'Settings',
  //   icon: CogIcon,
  //   subMenu: [
  //     {
  //       to: '/settings',
  //       name: 'Global Settings',
  //       icon: CogIcon,
  //     },
  //     {
  //       to: '/stop-prices',
  //       name: 'Stop Prices',
  //       icon: BanIcon,
  //     },
  //     {
  //       to: '/weight-prices',
  //       name: 'Weight Prices',
  //       icon: TrendingDownIcon,
  //     },
  //     {
  //       to: '/distance-prices',
  //       name: 'Distance Prices',
  //       icon: TrendingUpIcon,
  //     },
  //   ],
  // },
];
