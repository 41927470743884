import { createContext, useContext, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useQuery } from 'react-query';

import { useIdFromParams } from 'helpers';
import { defaultOrder, fetchOrder, Order } from 'services/orders';

const OrderPageContext = createContext<{
  order: Order;
  isLoading: boolean;
}>({} as any);

// This will be used in case we state shared inside the module
export const OrderPageProvider = ({ children = <Outlet /> }: Props) => {
  const { id: orderId, isCreate } = useIdFromParams();

  const { data: order, isLoading } = useQuery(['orders', orderId], () => fetchOrder(orderId), {
    enabled: !isCreate,
  });

  const providerValue = useMemo(
    () => ({
      order: order ?? defaultOrder,
      isLoading,
    }),
    [order, isLoading],
  );

  return <OrderPageContext.Provider value={providerValue}>{children}</OrderPageContext.Provider>;
};

export const useOrderPage = () => {
  return useContext(OrderPageContext);
};

interface Props {
  children?: React.ReactNode;
}
