import { ChartCard } from 'components';
import { useMileageCharts } from '../hooks';

export const MileageChart = () => {
  const { mileageData, innerMileageData } = useMileageCharts();
  return (
    <ChartCard
      title="Average Miles"
      subtitle="By Driver Group"
      chartData={mileageData}
      innerCardData={innerMileageData}
      height="100%"
      width="100%"
    />
  );
};
