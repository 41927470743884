import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Control, FormState, useFieldArray, useForm, UseFormRegister } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from 'react-query';

import { fetchAllDriverGroup } from 'services/driverGroups';
import { postOrderBulk, postOrderCsv, ItemPickupTimes } from 'services/orders';
import { MessageType, showMessage } from 'helpers';
import { fetchMerchant } from 'services/merchants';
import { useAuth } from 'GlobalProvider';

import { IMPORT_VALIDATION_SCHEMA } from './const';
import { BulkOrderForm } from '../types';
import { defaultBulkOrderForm, defaultBulkOrderRow } from '../consts';

const UploadPageContext = createContext<{
  formState: FormState<BulkOrderForm>;
  loadingUpload: boolean;
  control: Control<BulkOrderForm, any>;
  inputRef: React.RefObject<HTMLInputElement>;
  orders: BulkOrderForm['orders'];
  orderErrors: {
    row: number;
    errorMessages: string[];
  }[];
  driverGroups: { label: string; value: string }[];
  merchantId: string | undefined;
  merchantItemPickupTimes: ItemPickupTimes[];
  handleRowDelete: (index: number) => void;
  handleUploadCsv: (data: any) => void;
  handleButtonClick: () => void;
  handleAddNewRow: () => void;
  register: UseFormRegister<BulkOrderForm>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}>({} as any);

// This will be used in case we state shared inside the module
export const UploadPageProvider = ({ children = <Outlet /> }: Props) => {
  const { control, formState, register, handleSubmit, reset, setValue, clearErrors } = useForm<BulkOrderForm>({
    defaultValues: defaultBulkOrderForm,
    resolver: yupResolver(IMPORT_VALIDATION_SCHEMA),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { user } = useAuth();

  const { data: queryDriverGroups } = useQuery(['driverGroups', 1, 9999], () => fetchAllDriverGroup());
  const { data: queryMerchant } = useQuery(['merchant'], () => fetchMerchant(user && user.id));

  const { fields: orders, append, remove } = useFieldArray({ control, name: 'orders' });

  const [loadingUpload, setLoadingUpload] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const merchantId = queryMerchant?.id;

  const driverGroups = useMemo(
    () =>
      queryDriverGroups?.map((driverGroup) => ({
        label: driverGroup.name,
        value: driverGroup.id,
      })) ?? [],
    [queryDriverGroups],
  );

  const merchantDriverGroupId = useMemo(() => {
    return queryMerchant?.driverGroupId;
  }, [queryMerchant]);

  const merchantItemPickupTimes = useMemo(() => queryMerchant?.itemPickupTimes ?? [], [queryMerchant?.itemPickupTimes]);

  const orderErrors = useMemo(() => {
    const errorList: { row: number; errorMessages: string[] }[] = [];

    // formState.errors.orders is an array that can have undefined values
    // Object.keys() is used to get the indexes of the errors
    Object.keys(formState.errors.orders || []).forEach((key) => {
      const errorObject = formState.errors.orders?.[parseInt(key, 10)];
      const errorMessages = Object.values(errorObject || {}).map((error: any) => error.message);

      errorList.push({
        row: parseInt(key, 10) + 1,
        errorMessages,
      });
    });

    return errorList;
  }, [formState.errors]);

  const resetFileInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, []);

  useEffect(() => {
    if (merchantId && merchantDriverGroupId) {
      orders.forEach((_, index) => {
        setValue(`orders.${index}.deliveryTypeId`, merchantDriverGroupId);
      });
    }
  }, [merchantDriverGroupId, merchantId, orders, setValue]);

  useEffect(() => {
    clearErrors('orders');
    reset({ merchantId, orders: [] });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId]);

  useEffect(() => {
    if (!orders.length) {
      clearErrors();
      resetFileInput();
    }
  }, [orders, resetFileInput, clearErrors]);

  const handleRowDelete = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const handleButtonClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  const handleAddNewRow = useCallback(() => {
    append(defaultBulkOrderRow, { shouldFocus: false });
  }, [append]);

  const handleUploadCsv = useCallback(
    async (e: any) => {
      const { files } = e.target;

      if (files[0].type !== 'text/csv') {
        showMessage('Only .csv files are allowed', MessageType.Error);
        setLoadingUpload(false);
        return;
      }

      const formData = new FormData();
      formData.append('file', files[0]);
      setLoadingUpload(true);

      try {
        const orderCsvFile = await postOrderCsv(formData);
        setValue('orders', orderCsvFile);
        setLoadingUpload(false);
        showMessage('CSV file uploaded successfully', MessageType.Success);
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
        setLoadingUpload(false);
      }
    },
    [setValue],
  );

  const onSubmit = handleSubmit(async (data: any) => {
    setLoadingUpload(true);

    try {
      await postOrderBulk(data);
      setLoadingUpload(false);
      showMessage('Csv successfully imported.', MessageType.Success);
      reset(defaultBulkOrderForm);
    } catch (err: any) {
      const errorMsg = err?.response?.data?.title;
      showMessage(errorMsg, MessageType.Error);
      setLoadingUpload(false);
    }
  });

  const providerValue = useMemo(
    () => ({
      control,
      loadingUpload,
      formState,
      inputRef,
      orders,
      orderErrors,
      driverGroups,
      merchantId,
      merchantItemPickupTimes,
      handleRowDelete,
      handleAddNewRow,
      handleUploadCsv,
      handleButtonClick,
      register,
      onSubmit,
    }),
    [
      control,
      loadingUpload,
      formState,
      inputRef,
      orders,
      orderErrors,
      driverGroups,
      merchantId,
      merchantItemPickupTimes,
      handleRowDelete,
      handleAddNewRow,
      handleUploadCsv,
      handleButtonClick,
      register,
      onSubmit,
    ],
  );

  return <UploadPageContext.Provider value={providerValue}>{children}</UploadPageContext.Provider>;
};

export const useUploadPageProvider = () => {
  return useContext(UploadPageContext);
};

interface Props {
  children?: React.ReactNode;
}
