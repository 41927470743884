import { ApexChart } from 'components/Chart/Chart';
import { InnerChartCard } from 'components';
import { InnerChartCardData } from 'modules/Dashboard/DashboardPage/types';

type Props = {
  title: string;
  subtitle?: string;
  type?: 'donut' | 'pie' | 'bar' | 'line';
  width?: string | number;
  height?: string | number;
  chartData: {
    series?: any[];
    options: object;
  };
  innerCardData?: InnerChartCardData[];
};

export const ChartCard = ({ title, subtitle, type, width, height, chartData, innerCardData }: Props) => {
  return (
    <div className="flex flex-col w-full bg-gray-200 rounded-md shadow-md p-4 min-h-[600px] max-h-[600px] basis-[49.5%]">
      <div>
        <div className="text-center">
          <h2 className="text-xl text-slate-600 font-semibold">{title}</h2>
          <p className="text-xs text-slate-600 italic">{subtitle}</p>
        </div>
        <div className="grid grid-cols-12 col-span-12 mt-4 h-fit">
          {innerCardData && innerCardData.length
            ? innerCardData.map((data, index) => (
                <InnerChartCard key={data.title} cardData={data} index={index} dataLength={innerCardData.length} />
              ))
            : null}
        </div>
      </div>
      <div className="border bg-gray-100 shadow-sm rounded-md p-4 flex-1">
        <ApexChart
          options={chartData.options}
          series={chartData.series || []}
          type={type}
          width={width}
          height={height}
        />
      </div>
    </div>
  );
};
