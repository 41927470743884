import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { ResetPasswordRequest, resetPassword } from 'services/auth';
import { MessageType, showMessage } from 'helpers';
import { VALIDATION_SCHEMA } from './validations';

export const useResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const { register, control, handleSubmit, formState } = useForm<ResetPasswordRequest>({
    defaultValues: {
      email: searchParams.get('email') || '',
      passwordResetCode: searchParams.get('code') || '',
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (values) => {
    try {
      await resetPassword(values);
      showMessage('Password successfully changed', MessageType.Success);
      navigate('/sign-in');
    } catch (error: any) {
      showMessage(error.response.data.errorMessage, MessageType.Error);
    }
  });

  return { register, control, onSubmit, formState, searchParams };
};
