import { useQuery } from 'react-query';

import { ChartFilters, ReportingMileage, ReportingOrder, ReportingRevenue } from './types';
import { api } from '../api';
import { resToReportingMileage, resToReportingOrder, resToReportingRevenue } from './transformations';
import { resolveUrl } from './helpers';

const DASHBOARD_BASE_ROUTE = '/dashboard';

const DASHBOARD_REPORTING_ROUTE = {
  mileage: `${DASHBOARD_BASE_ROUTE}/mileage`,
  revenueShare: `${DASHBOARD_BASE_ROUTE}/revenue-share`,
  order: `${DASHBOARD_BASE_ROUTE}/order`,
};

enum QueryKeys {
  ReportingMileageKey = 'reporting_mileage',
  ReportingRevenueKey = 'reporting_revenue',
  ReportingOrderKey = 'reporting_order',
}

const fetchReportingMileage = async ({ startDate, endDate }: ChartFilters): Promise<ReportingMileage> => {
  const url = resolveUrl(DASHBOARD_REPORTING_ROUTE.mileage, startDate, endDate);

  const res = await api.get(url);

  return resToReportingMileage(res.data);
};

const fetchReportingRevenue = async ({ startDate, endDate }: ChartFilters): Promise<ReportingRevenue> => {
  const url = resolveUrl(DASHBOARD_REPORTING_ROUTE.revenueShare, startDate, endDate);

  const res = await api.get(url);

  return resToReportingRevenue(res.data);
};

const fetchReportingOrder = async ({ startDate, endDate }: ChartFilters): Promise<ReportingOrder> => {
  const url = resolveUrl(DASHBOARD_REPORTING_ROUTE.order, startDate, endDate);

  const res = await api.get(url);

  return resToReportingOrder(res.data);
};

//
// GET hooks
//

export const useReportingMileage = ({ startDate, endDate }: ChartFilters) =>
  useQuery([QueryKeys.ReportingMileageKey, startDate, endDate], () => fetchReportingMileage({ startDate, endDate }));

export const useReportingRevenue = ({ startDate, endDate }: ChartFilters) =>
  useQuery([QueryKeys.ReportingRevenueKey, startDate, endDate], () => fetchReportingRevenue({ startDate, endDate }));

export const useReportingOrder = ({ startDate, endDate }: ChartFilters) =>
  useQuery([QueryKeys.ReportingOrderKey, startDate, endDate], () => fetchReportingOrder({ startDate, endDate }));
