import { ReportingMileage, ReportingOrder, ReportingRevenue } from './types';

export const resToReportingMileage = (res: any): ReportingMileage => {
  return {
    mileageSum: res.mileageSum || 0,
    mileageAverage: res.mileageAverage || 0,
    mileagePerDriverGroup: res.mileagePerDriverGroup
      ? res.mileagePerDriverGroup.map((item: any) => ({
          driverGroupName: item.driverGroupName || '',
          averageMileage: item.averageMileage || 0,
        }))
      : [],
  };
};

export const resToReportingRevenue = (res: any): ReportingRevenue => {
  return {
    averageRevenue: res.averageRevenue || 0,
    totalRevenue: res.totalRevenue || 0,
    averageRevenuePerDriverGroup: res.averageRevenuePerDriverGroup
      ? res.averageRevenuePerDriverGroup.map((item: any) => ({
          driverGroup: item.driverGroup || '',
          averageRevenue: item.averageRevenue || 0,
        }))
      : [],
  };
};

export const resToReportingOrder = (res: any): ReportingOrder => {
  return {
    ordersOverTime: res.ordersOverTime
      ? res.ordersOverTime.map((item: any) => ({
          month: item.month || 0,
          orderCount: item.orderCount || 0,
        }))
      : [],
    driverGroupOrders: res.driverGroupOrders.map((item: any) => ({
      driverGroupName: item.driverGroupName || '',
      orderCount: item.orderCount || 0,
    })),
    totalNumberOfOrders: res.totalNumberOfOrders || 0,
    statusOrders: res.statusOrders.map((item: any) => ({
      status: item.status || '',
      orderCount: item.orderCount || 0,
    })),
  };
};
