import { ChartCard } from 'components';
import { useTotalOrderCharts } from '../hooks';

export const TotalOrdersByStatusChart = () => {
  const { totalOrdersByStatusData, innerCardTotalOrders } = useTotalOrderCharts();
  return (
    <ChartCard
      title="Total Orders"
      subtitle="By Status"
      chartData={totalOrdersByStatusData}
      height="100%"
      type="pie"
      innerCardData={innerCardTotalOrders}
    />
  );
};
