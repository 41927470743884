import { ChartCard } from 'components';
import { useTotalOrderCharts } from '../hooks';

export const TotalOrdersDriverGroupChart = () => {
  const { totalOrdersGroupData, innerCardTotalOrders } = useTotalOrderCharts();
  return (
    <ChartCard
      title="Total Orders"
      subtitle="By Driver Group"
      chartData={totalOrdersGroupData}
      height="100%"
      type="pie"
      innerCardData={innerCardTotalOrders}
    />
  );
};
