import { DriverGroup } from './types';

export const resToDriverGroup = (res: any): DriverGroup => {
  return {
    id: res.id,
    bookingFee: res.bookingFee ?? null,
    description: res.description ?? '',
    isTest: res.isTest ?? false,
    isHelper: res.isHelper ?? false,
    name: res.name ?? '',
    onFleetTeamId: res.onFleetTeamId ?? '',
    position: res.position ?? null,
    showInApp: res.showInApp ?? false,
    vehicleImageFileId: res.vehicleImageFileId ?? '',
    vehicleImageFileUrl: res.vehicleImageFileUrl ?? '',
    relatedDriverGroupIds: res.relatedDriverGroupIds ?? [],
  };
};

export const transformToDriverGroupsFilters = (filters: any): any => {
  return {
    name: filters.name,
  };
};
