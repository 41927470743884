import { currencyFormatter } from 'helpers';
import { ChartType, InnerChartCardData } from 'modules/Dashboard/DashboardPage';
import { useDashBoardPage } from 'modules/Dashboard/hooks';
import { useEffect, useMemo, useState } from 'react';
import { initialRevenueData } from './RevenueChart/consts';

export const useRevenueCharts = () => {
  const { dataByRevenue } = useDashBoardPage();

  const [revenueData, setRevenueData] = useState<ChartType>(initialRevenueData);

  const revenueDataFormat = useMemo(() => {
    const totalRevenueSum = dataByRevenue?.totalRevenue;
    const averageRevenue = dataByRevenue?.averageRevenue;
    const series = dataByRevenue?.averageRevenuePerDriverGroup.map((i) => i.averageRevenue) || [];
    const labels = dataByRevenue?.averageRevenuePerDriverGroup.map((i) => i.driverGroup) || [];

    return {
      totalRevenueSum,
      averageRevenue,
      labels,
      series,
    };
  }, [dataByRevenue]);

  useEffect(() => {
    setRevenueData((old) => {
      return {
        series: [
          {
            name: 'Average revenue',
            data: revenueDataFormat?.series,
          },
        ],
        options: {
          ...old.options,
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            y: {
              formatter: (val: number) => currencyFormatter(val),
            },
          },
          xaxis: {
            tickAmount: 4,
            categories: revenueDataFormat.labels,
            labels: {
              formatter: (val: number) => currencyFormatter(val),
            },
          },
        },
        totalSum: revenueDataFormat.totalRevenueSum || 0,
        averageSum: revenueDataFormat.averageRevenue || 0,
        type: 'revenue',
      };
    });
  }, [revenueDataFormat]);

  const innerCardAverageRevenue: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: revenueDataFormat.totalRevenueSum,
        type: 'currency',
        title: 'Total revenue',
      },
      {
        data: revenueDataFormat.averageRevenue,
        type: 'currency',
        title: 'Average revenue',
      },
    ];
  }, [revenueDataFormat]);

  return { revenueData, innerCardAverageRevenue };
};
