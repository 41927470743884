import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { BulkOrder, BulkOrderForm } from 'modules/Upload/types';

import { api } from '../api';
import { resToOrder, transformOrderBulk, transformToOrderFilters, transformToPostBulkOrder } from './transformations';
import { Order } from './types';

const ordersUrl = '/merchants/me/orders';

export const fetchOrders = async (pagination: PaginationRequest): Promise<DataWithMeta<Order>> => {
  const url = paginationRequestToUrl(ordersUrl, pagination, transformToOrderFilters);

  const resp = await api.get(url);

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToOrder),
  };
};

export const postOrderCsv = async (data: FormData): Promise<BulkOrder[]> => {
  const resp = await api.post(`/orders/csv/parse`, data);
  return resp.data.map(transformOrderBulk);
};

export const postOrderBulk = async (data: BulkOrderForm) => {
  const resp = await api.post(`/orders/bulk`, transformToPostBulkOrder(data));
  return resp.data;
};

export const fetchOrder = async (id: string): Promise<Order> => {
  const resp = await api.get(`${ordersUrl}/${id}`);
  const order = resToOrder(resp.data);

  return order;
};
