import { useReportingMileage, useReportingOrder, useReportingRevenue } from 'services/reporting';
import { useDashboardPageContext } from './DashboardPage';

export const useDashBoardPage = () => {
  const { startDate, endDate } = useDashboardPageContext();

  const { data: dataByMileage } = useReportingMileage({ startDate, endDate });
  const { data: dataByOrder } = useReportingOrder({ startDate, endDate });
  const { data: dataByRevenue } = useReportingRevenue({ startDate, endDate });

  return { dataByMileage, dataByOrder, dataByRevenue };
};
