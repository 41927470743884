import { FormItem, Input } from 'components';
import { formatDateShort } from 'helpers';
import { addressToString } from 'services/addresses/helpers';
import { OrderStop, Order, PickupTypeName } from 'services/orders';

interface Props {
  selectedOrderStop: OrderStop;
  order: Order;
}

const getPickupTypeName = (pickupType: string | null, startDateTime: string | null): string => {
  if (!pickupType) return '';

  const pickupTypeName = PickupTypeName[pickupType] || '';
  const shortDate = startDateTime ? formatDateShort(startDateTime) : '';

  return `${pickupTypeName} - ${shortDate}`;
};

export const OrderPickupDetails = ({ selectedOrderStop, order }: Props) => {
  const pickupAddress = selectedOrderStop?.fromAddress;

  return (
    <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
      <FormItem label="Pickup Address">
        <Input type="text" disabled value={pickupAddress ? addressToString(pickupAddress) : ''} />
      </FormItem>

      <FormItem label="Contact Name">
        <Input type="text" disabled value={selectedOrderStop?.senderPersonName ?? ''} />
      </FormItem>

      <FormItem label="Contact Phone">
        <Input type="text" disabled value={selectedOrderStop?.senderPersonPhone ?? ''} />
      </FormItem>

      <FormItem label="Pickup Information">
        <Input type="text" disabled value={selectedOrderStop?.driverPickupNote ?? ''} />
      </FormItem>

      <FormItem label="Pickup Type">
        <Input type="text" disabled value={getPickupTypeName(order?.pickupType, order?.startDateTime)} />
      </FormItem>
    </div>
  );
};
