import { ChartCard } from 'components';
import { useRevenueCharts } from '../hooks';

export const RevenueChart = () => {
  const { innerCardAverageRevenue, revenueData } = useRevenueCharts();
  return (
    <ChartCard
      title="Revenue Share"
      subtitle="By Driver Group"
      chartData={revenueData}
      innerCardData={innerCardAverageRevenue}
      height="100%"
    />
  );
};
