export const resolveUrl = (route: string, startDate: string | null, endDate: string | null) => {
  let url = route;

  if (startDate) {
    url = `${route}?startDateTime=${startDate}`;

    if (endDate) {
      url = `${route}?startDateTime=${startDate}&endDateTime=${endDate}`;
    }
  }

  return url;
};
