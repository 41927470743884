import { CellProps } from 'react-table';

import { TableColumn, Tooltip } from 'components';
import { formatDate, formatPrice, shortenId } from 'helpers';
import { Order } from 'services/orders';

export const columns = (): TableColumn<Order>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    isSortable: true,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'Date',
    accessor: 'createdAt' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      const date = rowData.createdAt ? formatDate(new Date(rowData.createdAt)) : '';

      return <>{date}</>;
    },
  },
  {
    Header: 'Delivery Date',
    accessor: 'startDateTime' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      const date = rowData.startDateTime ? formatDate(new Date(rowData.startDateTime)) : '';

      return <>{date}</>;
    },
  },
  {
    Header: 'Customer',
    accessor: 'customer.name' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;

      if (!rowData.customer) {
        return <></>;
      }

      return <>{`${rowData.customer.userFirstName} ${rowData.customer.userLastName}`}</>;
    },
  },
  {
    Header: 'Pickup Address',
    accessor: 'pickupAddress' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      return <>{rowData.pickupAddress}</>;
    },
  },
  {
    Header: 'Delivery Address',
    accessor: 'deliveryAddresses' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      const { deliveryAddresses } = rowData;

      if (deliveryAddresses.length === 0) {
        return <></>;
      }

      return (
        <Tooltip tooltipMessage={deliveryAddresses.join('<br/>')}>{`${deliveryAddresses[0]} ${
          deliveryAddresses.length > 1 ? `(+${deliveryAddresses.length - 1} more)` : ''
        }`}</Tooltip>
      );
    },
  },
  {
    Header: 'Amount',
    accessor: 'merchantPaymentAmount' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      return <>{formatPrice(rowData.merchantPayment)}</>;
    },
  },
  {
    Header: 'Status',
    accessor: 'orderStatus' as any,
    isSortable: true,
  },
];
