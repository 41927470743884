import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Control, useForm, UseFormRegister } from 'react-hook-form';

import { SortDirection, usePagination } from 'helpers';
import { fetchOrders, Order, OrderStatus } from 'services/orders';

import { OrderAdvancedSearchForm } from '../types';
import { defaultOrderAdvancedSearchForm } from '../consts';
import { OrderRowForm } from './types';

const OrdersPageContext = createContext<{
  orders: Order[];
  metaData: any;
  currentPage: number;
  search: string;
  isLoading: boolean;
  control: Control<OrderAdvancedSearchForm, any>;
  controlTable: Control<OrderRowForm, any>;
  register: UseFormRegister<OrderAdvancedSearchForm>;
  onSearchChange: (search: string) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onRowClick: (row: Order) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onClearFilters: () => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const OrdersPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  } = usePagination({ sortBy: 'createdAt', sortDirection: SortDirection.Desc });

  const { register, control, handleSubmit, reset } = useForm<OrderAdvancedSearchForm>({
    defaultValues: defaultOrderAdvancedSearchForm,
  });

  const { control: controlTable, reset: resetTable } = useForm<OrderRowForm>({
    defaultValues: { orders: [] },
  });

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['orders', currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters],
    () =>
      fetchOrders({
        page: currentPage,
        pageSize,
        sortBy,
        sortDirection,
        search: debouncedSearch,
        filters: { status: OrderStatus.Delivered },
      }),
  );

  const orders = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  useEffect(() => {
    // check if filters object has the same keys as defaultOrderAdvancedSearchForm
    const hasAllKeys = Object.keys(defaultOrderAdvancedSearchForm).every((key) => key in filters);
    if (hasAllKeys) {
      reset(filters as OrderAdvancedSearchForm);
    }
  }, [filters, reset]);

  useEffect(() => {
    resetTable({ orders: orders.map((o) => ({ id: o.id, paymentStatus: o.paymentStatus })) });
  }, [orders, resetTable]);

  const onSubmit = handleSubmit((data: OrderAdvancedSearchForm) => {
    onFiltersChange(data as any);
  });

  const onRowClick = useCallback(
    (row: Order) => {
      navigate(`/orders/${row.id}`);
    },
    [navigate],
  );

  const onClearFilters = useCallback(() => {
    reset(defaultOrderAdvancedSearchForm);
    onSubmit();
  }, [reset, onSubmit]);

  const providerValue = useMemo(
    () => ({
      orders,
      metaData,
      currentPage,
      search,
      isLoading: driversLoading,
      control,
      controlTable,
      register,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onSubmit,
      onClearFilters,
    }),
    [
      orders,
      metaData,
      currentPage,
      search,
      driversLoading,
      control,
      controlTable,
      register,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onSubmit,
      onClearFilters,
    ],
  );

  return <OrdersPageContext.Provider value={providerValue}>{children}</OrdersPageContext.Provider>;
};

export const useOrdersPage = () => {
  return useContext(OrdersPageContext);
};

interface Props {
  children?: React.ReactNode;
}
