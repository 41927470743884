const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function displayMonth(number: number) {
  if (!number) {
    return '';
  }

  return months[number - 1];
}
