import { numberFormatter } from 'helpers';
import { ChartType, InnerChartCardData } from 'modules/Dashboard/DashboardPage/types';
import { useDashBoardPage } from 'modules/Dashboard/hooks';
import { useEffect, useMemo, useState } from 'react';
import { reportingOrderStatus } from 'services/reporting/consts';
import { displayMonth } from './helpers';
import { initialTotalOrdersByStatusData } from './TotalOrdersByStatusChart/consts';
import { initialTotalOrdersGroupData } from './TotalOrdersDriverGroupChart/consts';
import { initialTotalOrderTimeData } from './TotalOrdersOverTimeChart/consts';

export const useTotalOrderCharts = () => {
  const { dataByOrder } = useDashBoardPage();

  const [totalOrdersGroupData, setTotalOrdersGroupData] = useState<ChartType>(initialTotalOrdersGroupData);
  const [totalOrdersTimeData, setTotalOrdersTimeData] = useState<ChartType>(initialTotalOrderTimeData);
  const [totalOrdersByStatusData, setTotalOrdersByStatusData] = useState<ChartType>(initialTotalOrdersByStatusData);

  const totalOrdersTimeDataFormat = useMemo(() => {
    const series = dataByOrder?.ordersOverTime.map((item) => item.orderCount) || [];
    const labels = dataByOrder?.ordersOverTime.map((item) => item.month) || [];

    return {
      series,
      labels,
    };
  }, [dataByOrder]);

  const totalOrdersGroupFormat = useMemo(() => {
    const series = dataByOrder?.driverGroupOrders.map((item) => item.orderCount) || [];
    const labels = dataByOrder?.driverGroupOrders.map((item) => item.driverGroupName) || [];

    return {
      series,
      labels,
    };
  }, [dataByOrder]);

  const totalOrdersByStatusFormat = useMemo(() => {
    const series = dataByOrder?.statusOrders.map((item) => item.orderCount) || [];
    const labels = dataByOrder?.statusOrders.map((item) => item.status) || [];

    return {
      series,
      labels,
    };
  }, [dataByOrder]);

  useEffect(() => {
    setTotalOrdersTimeData((old) => {
      return {
        series: [
          {
            name: 'Total Orders',
            data: totalOrdersTimeDataFormat.series,
          },
        ],
        options: {
          ...old.options,
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: totalOrdersTimeDataFormat.labels,
            labels: {
              formatter: (val: number) => displayMonth(val),
            },
          },
        },
      };
    });
  }, [totalOrdersTimeDataFormat]);

  useEffect(() => {
    setTotalOrdersGroupData((old) => {
      return {
        series: totalOrdersGroupFormat?.series,
        options: {
          ...old.options,
          labels: totalOrdersGroupFormat?.labels,
          colors: ['#3849E5', '#282460', '#8c579f', '#00bfa5', '#c80d57', '#c6c2d6'],
          tooltip: {
            y: {
              formatter: (val: number) => numberFormatter(val),
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
            },
          },
        },
      };
    });
  }, [totalOrdersGroupFormat]);

  useEffect(() => {
    setTotalOrdersByStatusData((old) => {
      return {
        series: totalOrdersByStatusFormat?.series,
        options: {
          ...old.options,
          labels: totalOrdersByStatusFormat?.labels.map((l) => reportingOrderStatus[l]),
          colors: ['#3849E5', '#282460', '#8c579f', '#00bfa5', '#c80d57', '#c6c2d6'],
          tooltip: {
            y: {
              formatter: (val: number) => numberFormatter(val),
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
            },
          },
        },
      };
    });
  }, [totalOrdersByStatusFormat]);

  const innerCardTotalOrders: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: dataByOrder?.totalNumberOfOrders,
        type: 'display',
        title: 'Total Orders',
      },
    ];
  }, [dataByOrder]);

  return { totalOrdersGroupData, totalOrdersTimeData, totalOrdersByStatusData, innerCardTotalOrders };
};
