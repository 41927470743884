import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { OrdersPage, OrdersPageProvider } from 'modules/Orders/OrdersPage';
import { OrdersProvider } from 'modules/Orders';
import { ResetPasswordPage } from 'modules/ResetPasswordPage';
import { OrderPage, OrderPageProvider } from 'modules/Orders/OrderPage';
import { UploadProvider } from 'modules/Upload/UploadProvider';
/* import { ChatPage } from 'modules/ChatPage/ChatPage'; */
import { UploadPage, UploadPageProvider } from 'modules/Upload/UploadPage';
import { MainLayout } from './components';
import { GlobalProvider } from './GlobalProvider';
import { SignIn } from './modules/SignIn';
import { DashboardPage, DashboardPageProvider } from './modules/Dashboard';

import { ProfilePage } from './modules/Profile';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
dayjs.extend(localizedFormat);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route element={<DashboardPageProvider />}>
              <Route index element={<DashboardPage />} />
            </Route>
            {/*    <Route path="chat" element={<ChatPage />} /> */}
            <Route path="profile" element={<ProfilePage />} />
            <Route path="upload" element={<UploadProvider />}>
              <Route element={<UploadPageProvider />}>
                <Route index element={<UploadPage />} />
              </Route>
            </Route>
            <Route path="orders" element={<OrdersProvider />}>
              <Route element={<OrdersPageProvider />}>
                <Route index element={<OrdersPage />} />
              </Route>
              <Route path=":id" element={<OrderPageProvider />}>
                <Route index element={<OrderPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
        </Routes>
      </GlobalProvider>
    </QueryClientProvider>
  );
}

export default App;
