import { ChartType, InnerChartCardData } from 'modules/Dashboard/DashboardPage';
import { useDashBoardPage } from 'modules/Dashboard/hooks';
import { useEffect, useMemo, useState } from 'react';
import { initialMileageData } from './MileageChart/consts';

export const useMileageCharts = () => {
  const { dataByMileage } = useDashBoardPage();

  const [mileageData, setMileageData] = useState<ChartType>(initialMileageData);

  const mileageDataFormat = useMemo(() => {
    const totalMileageSum = dataByMileage?.mileageSum;
    const totalMileageAverage = dataByMileage?.mileageAverage;
    const series = dataByMileage?.mileagePerDriverGroup.map((i) => i.averageMileage) || [];
    const labels = dataByMileage?.mileagePerDriverGroup.map((i) => i.driverGroupName) || [];

    return {
      totalMileageSum,
      totalMileageAverage,
      series,
      labels,
    };
  }, [dataByMileage]);

  useEffect(() => {
    setMileageData((old) => {
      return {
        series: [
          {
            name: 'Average mileage',
            data: mileageDataFormat.series,
          },
        ],
        options: {
          ...old.options,
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            tickAmount: 4,
            categories: mileageDataFormat.labels,
          },
        },
        totalSum: mileageDataFormat.totalMileageSum || 0,
        averageSum: mileageDataFormat.totalMileageAverage || 0,
        type: 'mileage',
      };
    });
  }, [mileageDataFormat]);

  const innerMileageData: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: mileageDataFormat.totalMileageSum,
        type: 'display',
        title: 'Total Mileage',
      },
      {
        data: mileageDataFormat.totalMileageAverage,
        type: 'display',
        title: 'Average Mileage',
      },
    ];
  }, [mileageDataFormat]);

  return { mileageData, innerMileageData };
};
