export const initialMileageData = {
  series: [
    {
      data: [],
    },
  ],
  options: {
    colors: ['#FF2934'],
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
  },
  totalSum: 0,
  averageSum: 0,
  type: '',
};
