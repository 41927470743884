/* eslint-disable jsx-a11y/label-has-associated-control */
import { Container, Header, HeaderSearch, Table } from 'components';
import { Order } from 'services/orders';
import { useOrdersPage } from './OrdersPageProvider';
import { columns } from './const';

export const OrdersPage = () => {
  const {
    orders,
    metaData,
    currentPage,
    isLoading,
    search,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onSearchChange,
    onSortChange,
  } = useOrdersPage();

  return (
    <>
      <Header title="Orders">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e: any) => onSearchChange(e.target.value)} value={search} />
        </div>
      </Header>

      <Container>
        <Table<Order>
          data={orders ?? []}
          columns={columns()}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>
    </>
  );
};
