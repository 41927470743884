import dayjs from 'dayjs';
import * as yup from 'yup';

export const VALIDATION_SCHEMA = yup.object().shape({
  customStartDate: yup
    .string()
    .required('Start date is required')
    .test('start-date-not-in-the-future', 'Start date can not be in the future', (value) => {
      return !dayjs(value).isAfter(dayjs(new Date()), 'date');
    })
    .nullable(),
  customEndDate: yup
    .string()
    .notRequired()
    .test('end-date-not-in-the-future', 'End date can not be in the future', (value) => {
      return !dayjs(value).isAfter(dayjs(new Date()), 'date');
    })
    .nullable(),
});
