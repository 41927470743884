import { User } from 'services/users';
import { Address } from '../addresses';

// temp types ->

export interface Merchant {
  id: string;
  user: User | null;
  businessName: string;
  isSendingPriceToOnfleet: boolean;
  driverGroupId: string;
  itemPickupTimes: ItemPickupTimes[] | null;
  merchantPaymentPercentage: number;
}

export interface ItemPickupTimes {
  name: string;
  pickupTimeFrom: string;
  pickupTimeTo: string;
}

export interface Customer {
  id: string;
  user: User | null;
  zipCode: string;
  leadSource: string;
  referralCode: string;
  customerTypeId: string;
  isTest: boolean;
  isToBeInvoiced: boolean;
  userFirstName: string;
  userLastName: string;
}

export interface Driver {
  id: string;
  approveDenyNote: string;
  description: string;
  driverGroupIds: string[];
  driverGroupName: string;
  driversLicenseExpirationDate: string;
  driversLicenseNumber: string;
  driversLicenseState: string;
  driversLicenseFileId: string;
  driversLicenseFileUrl: string;
  expectedAvailabilityId: string;
  firstHelperPaymentPercentage: number | null;
  isHelper: boolean;
  maximumDrivingDistanceMiles: number | null;
  orderPaymentPercentage: number | null;
  profilePictureFileId: string;
  profilePictureFileUrl: string;
  secondHelperPaymentPercentage: number | null;
  status: DriverStatus | null;
  user: User | null;
  userFirstName: string;
  userLastName: string;
  vehicleColor: string;
  vehicleInsuranceFileId: string;
  vehicleInsuranceFileUrl: string;
  vehicleRegistrationFileId: string;
  vehicleRegistrationFileUrl: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: number | null;
}

export enum DriverStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
  DELETED = 'deleted',
}

// temp types <--

export enum PaymentType {
  CreditCard = 'cc',
  Invoiced = 'invoice',
}

export enum PaymentStatus {
  Paid = 'paid',
  Pending = 'pending',
  Failed = 'failed',
}

export enum OrderStatus {
  NotAssigned = 'notAssigned',
  Assigned = 'assigned',
  OutForPickup = 'outForPickup',
  OutForDelivery = 'outForDelivery',
  Delivered = 'delivered',
  CancelledOrFailed = 'cancelledOrFailed',
  PickedUp = 'pickedUp',
}

export const OrderStatusName: Record<string, string> = {
  notAssigned: 'Not Assigned',
  assigned: 'Assigned',
  outForPickup: 'Out For Pickup',
  outForDelivery: 'Out For Delivery',
  delivered: 'Delivered',
  cancelledOrFailed: 'Cancelled Or Failed',
  pickedUp: 'Picked Up',
};

export enum DeliveryWindow {
  Morning = 'morning',
  LateMorning = 'lateMorning',
  Noon = 'noon',
  LateNoon = 'lateNoon',
  Afternoon = 'afterNoon',
}

export const DeliveryWindowName: Record<string, string> = {
  morning: 'Morning',
  lateMorning: 'Late Morning',
  noon: 'Noon',
  lateNoon: 'Late Noon',
  afterNoon: 'Afternoon',
};

export const DeliveryWindowTime: Record<string, string> = {
  morning: '8:00 AM - 12:00 PM',
  lateMorning: '10:00 AM - 2:00 PM',
  noon: '12:00 PM - 4:00 PM',
  lateNoon: '2:00 PM - 6:00 PM',
  afterNoon: '4:00 PM - 8:00 PM',
};

export enum ItemPickupTime {
  morning = '8:00 AM - 12:00 PM',
  noon = '12:00 PM - 4:00 PM',
  afterNoon = '4:00 PM - 8:00 PM',
}

export enum PickupType {
  Today = 'today',
  Expedited = 'expedited',
  Scheduled = 'schedule',
}

export const PickupTypeName: Record<string, string> = {
  today: 'Today',
  expedited: 'Expedited',
  schedule: 'Scheduled',
};

export interface Order {
  id: string;
  bookingPrice: number | null;
  createdAt: string;
  customer: Customer | null;
  customerId: string;
  customerName: string;
  deliveryAddresses: string[];
  deliveryWindow: DeliveryWindow | null;
  deviceType: string;
  driver: Driver | null;
  driverGroupId: string;
  driverGroupName: string;
  driverId: string;
  driverName: string;
  driverPayment: number | null;
  expeditedFee: number | null;
  extraTip: number | null;
  finalPrice: number | null;
  firstHelperFee: number | null;
  firstHelperLoadPrice: number | null;
  firstHelperUnloadPrice: number | null;
  height: string | null;
  helper: Driver | null;
  helperPayment: number | null;
  images: OrderImage[];
  itemsDescription: string;
  length: string | null;
  loadHelperCount: number | null;
  loadHelperPrice: number | null;
  merchant: Merchant | null;
  merchantId: string;
  merchantOrderNumber: string;
  merchantPayment: number;
  mileagePrice: number | null;
  orderStatus: string;
  paymentDate: string;
  paymentStatus: PaymentStatus | null;
  paymentTransactionId: string;
  paymentType: PaymentType | null;
  pickupAddress: string;
  pickupType: PickupType | null;
  promoCodeDiscount: number | null;
  promoCodeId: string;
  secondHelperFee: number | null;
  secondHelperLoadPrice: number | null;
  secondHelperUnloadPrice: number | null;
  startDateTime: string;
  stops: OrderStop[];
  tipOverride: number | null;
  tipPrice: number | null;
  totalMileage: number | null;
  trailerPrice: number | null;
  unloadHelperCount: number | null;
  unloadHelperPrice: number | null;
  weight: number | null;
  weightPrice: number | null;
  width: string | null;
  pickupTimeFrom: string;
  pickupTimeUntil: string;
  driverBonusPayment: number;
  helperBonusPayment: number;
}

export interface OrderStop {
  id: string;
  orderId: string;
  fromAddress: Address | null;
  toAddress: Address | null;
  stopNumber: number | null;
  shippingDistance: number | null;
  shippingDistancePrice: number | null;
  itemQuantity: number | null;
  itemLength: number | null;
  itemWidth: number | null;
  itemHeight: number | null;
  itemWeight: number | null;
  deliveryDateTime: string;
  pickupDateTime: string;
  driverPickupNote: string;
  driverDeliveryNote: string;
  senderPersonName: string;
  senderPersonPhone: string;
  receivePersonName: string;
  receivePersonPhone: string;
}

export interface OrderImage {
  id: string;
  fileId: string;
  fileUrl: string;
}
