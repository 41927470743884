import { ProfileForm } from 'modules/Profile/types';
import { api } from 'services';

import { resToUser, User } from '../users';
import { resToAuthData, userToPutAuthUser } from './transformations';
import { AuthData, LoginRequest, ResetPasswordRequest } from './types';

export const login = async (data: LoginRequest): Promise<AuthData> => {
  const resp = await api.post('/users/auth/authenticate', data);

  const authUser = resToAuthData(resp.data);

  if (!authUser.user?.isMerchant) {
    throw new Error('User is not a merchant');
  }

  return resToAuthData(resp.data);
};

export const updateAuthUser = async (data: ProfileForm, user: User) => {
  const resp = await api.put('/users/me', userToPutAuthUser(data, user));
  return resToUser(resp.data);
};

export const resetPassword = async (data: ResetPasswordRequest): Promise<{}> => {
  const resp = await api.post('/users/auth/reset-password', data);
  return resp.data;
};
