import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Button, Container, DatePicker, FilterDropdown, FormItem, Header, Modal } from 'components';
import { useAuth } from 'GlobalProvider';
import { MileageChart } from './Charts/MileageCharts';
import { TotalOrdersByStatusChart, TotalOrdersDriverGroupChart, TotalOrdersOverTimeChart } from './Charts/OrderCharts';
import { RevenueChart } from './Charts/RevenueCharts';
import { useDashboardPageContext } from './DashboardPageProvider';

dayjs.extend(customParseFormat);

export function DashboardPage() {
  const { user } = useAuth();
  const {
    chartFilterOptions,
    control,
    openDatePickerModal,
    formState,
    onSubmit,
    onDatePickerModalClose,
    onClearFilter,
  } = useDashboardPageContext();

  return (
    <div>
      <Header title="Dashboard">
        <FilterDropdown items={chartFilterOptions} onClearFilter={onClearFilter} />
      </Header>
      <Container>
        <h1 className="text-2xl">
          Welcome {user?.firstName} {user?.lastName}
        </h1>
        <div className="flex xl:flex-row flex-wrap flex-col justify-between mt-6 gap-y-3">
          <MileageChart />
          <RevenueChart />
          <TotalOrdersOverTimeChart />
          <TotalOrdersByStatusChart />
          <TotalOrdersDriverGroupChart />
        </div>
      </Container>

      <Modal title="Choose a custom date range" open={openDatePickerModal} onClose={onDatePickerModalClose}>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <FormItem label="Start Date" error={formState.errors.customStartDate?.message}>
            <DatePicker
              name="customStartDate"
              placeholder="Select start date"
              control={control}
              error={!!formState.errors.customStartDate?.message}
            />
          </FormItem>
          <FormItem label="End Date" error={formState.errors.customEndDate?.message}>
            <DatePicker
              name="customEndDate"
              placeholder="Select end date"
              control={control}
              error={!!formState.errors.customEndDate?.message}
            />
          </FormItem>
        </div>
        <div className="flex justify-end mt-4">
          <Button type="button" label="Apply" onClick={onSubmit} />
        </div>
      </Modal>
    </div>
  );
}
