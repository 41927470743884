import { PageType } from 'helpers';
import { Order } from './types';

export const defaultOrder: Order = {
  id: PageType.Create,
  bookingPrice: null,
  createdAt: '',
  customer: null,
  customerId: '',
  customerName: '',
  deliveryAddresses: [],
  deliveryWindow: null,
  deviceType: '',
  driver: null,
  driverGroupId: '',
  driverGroupName: '',
  driverId: '',
  driverName: '',
  driverPayment: null,
  expeditedFee: null,
  extraTip: null,
  finalPrice: null,
  firstHelperFee: null,
  firstHelperLoadPrice: null,
  firstHelperUnloadPrice: null,
  height: '',
  helper: null,
  helperPayment: null,
  images: [],
  itemsDescription: '',
  length: '',
  loadHelperCount: null,
  loadHelperPrice: null,
  merchant: null,
  merchantId: '',
  merchantOrderNumber: '',
  mileagePrice: null,
  merchantPayment: 0,
  orderStatus: '',
  paymentDate: '',
  paymentStatus: null,
  paymentTransactionId: '',
  paymentType: null,
  pickupAddress: '',
  pickupType: null,
  promoCodeDiscount: null,
  promoCodeId: '',
  secondHelperFee: null,
  secondHelperLoadPrice: null,
  secondHelperUnloadPrice: null,
  startDateTime: '',
  stops: [],
  tipOverride: null,
  tipPrice: null,
  totalMileage: null,
  trailerPrice: null,
  unloadHelperCount: null,
  unloadHelperPrice: null,
  weight: null,
  weightPrice: null,
  width: '',
  pickupTimeFrom: '',
  pickupTimeUntil: '',
  driverBonusPayment: 0,
  helperBonusPayment: 0,
};
