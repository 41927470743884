import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { Control, FormState, useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';
import { VALIDATION_SCHEMA } from './validations';

export const DashboardPageContext = React.createContext<{
  startDate: string | null;
  endDate: string | null;
  control: Control<{ customStartDate: string; customEndDate: string }, any>;
  openDatePickerModal: boolean;
  formState: FormState<{
    customStartDate: string;
    customEndDate: string;
  }>;
  chartFilterOptions: {
    label: string;
    id: string;
    customDateRange?: string | null;
    onItemClick: () => void;
  }[];
  onDatePickerModalClose: () => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onClearFilter: () => void;
}>({} as any);

export const DashboardPageProvider = ({ children = <Outlet /> }: Props) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [openDatePickerModal, setOpenDatePickerModal] = useState<boolean>(false);

  const {
    control,
    formState,
    reset: resetDatePickerForm,
    handleSubmit,
  } = useForm({
    defaultValues: {
      customStartDate: '',
      customEndDate: '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const onClearFilter = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    resetDatePickerForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDatePickerModalClose = useCallback(() => {
    setOpenDatePickerModal(false);
    resetDatePickerForm();
    onClearFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit(({ customStartDate, customEndDate }) => {
    setStartDate(dayjs(customStartDate).format('MM-DD-YYYY'));
    if (customEndDate) {
      setEndDate(dayjs(customEndDate).format('MM-DD-YYYY'));
    }
    setOpenDatePickerModal(false);
  });

  const handleCustomOptionClick = useCallback(() => {
    setOpenDatePickerModal(true);
    setStartDate(null);
    setEndDate(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedCustomDateRange = useMemo(() => {
    if (startDate && endDate) {
      return `${startDate} / ${endDate}`;
    }
    if (startDate && !endDate) {
      return `${startDate} / ${dayjs().format('MM-DD-YYYY')}`;
    }
    return null;
  }, [endDate, startDate]);

  const chartFilterOptions = useMemo(
    () => [
      {
        label: 'Last 24h',
        id: 'last_24h',
        onItemClick: () => setStartDate(dayjs().subtract(1, 'day').format('MM-DD-YYYY')),
      },
      {
        label: 'Last 7 days',
        id: 'last_7_days',
        onItemClick: () => setStartDate(dayjs().subtract(7, 'day').format('MM-DD-YYYY')),
      },
      {
        label: 'Last 30 days',
        id: 'last_30_days',
        onItemClick: () => setStartDate(dayjs().subtract(30, 'day').format('MM-DD-YYYY')),
      },
      {
        label: 'Last 120 days',
        id: 'last_120_days',
        onItemClick: () => setStartDate(dayjs().subtract(120, 'day').format('MM-DD-YYYY')),
      },
      {
        label: 'Custom',
        customDateRange: selectedCustomDateRange || '',
        id: 'custom',
        onItemClick: () => handleCustomOptionClick(),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCustomDateRange],
  );

  const providerValue = useMemo(
    () => ({
      chartFilterOptions,
      control,
      endDate,
      openDatePickerModal,
      startDate,
      formState,
      onClearFilter,
      onDatePickerModalClose,
      onSubmit,
    }),
    [
      chartFilterOptions,
      control,
      endDate,
      openDatePickerModal,
      startDate,
      formState,
      onClearFilter,
      onDatePickerModalClose,
      onSubmit,
    ],
  );

  return <DashboardPageContext.Provider value={providerValue}>{children}</DashboardPageContext.Provider>;
};

export const useDashboardPageContext = () => React.useContext(DashboardPageContext);

interface Props {
  children?: React.ReactNode;
}
