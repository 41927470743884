import { ItemPickupTimes } from 'services/orders';
import { resToUser } from '../users';
import { Merchant } from './types';

export const resToMerchant = (res: any): Merchant => {
  return {
    id: res.id,
    user: res.user ? resToUser(res.user) : null,
    businessName: res.businessName ?? '',
    isSendingPriceToOnfleet: res.isSendingPriceToOnfleet ?? false,
    driverGroupId: res.driverGroupId ?? '',
    itemPickupTimes: (res.itemPickupTimes ?? []).map(transformItemPickupTimes),
    merchantPaymentPercentage: res.merchantPaymentPercentage,
  };
};

export const transformItemPickupTimes = (res: any): ItemPickupTimes => {
  return {
    name: res.name ?? '',
    pickupTimeFrom: res.pickupTimeFrom ?? '',
    pickupTimeTo: res.pickupTimeTo ?? '',
  };
};
