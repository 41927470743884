import { FormItem, Input } from 'components';
import { formatDate } from 'helpers';
import { OrderStatusName } from 'services/orders';
import { useOrderPage } from '../../OrderPageProvider';

export const OrderSummary = () => {
  const { order } = useOrderPage();

  const startDate = order.startDateTime ? formatDate(new Date(order.startDateTime)) : '';
  const customerName = `${order.customer?.userFirstName || ''} ${order.customer?.userLastName || ''}`;
  const driverName = order.driver ? `${order.driver?.userFirstName} ${order.driver?.userLastName}` : 'Not assigned';
  const helperName = order.helper ? `${order.helper?.userFirstName} ${order.helper?.userLastName}` : 'Not assigned';

  return (
    <>
      <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
        <FormItem label="ID">
          <Input type="text" disabled value={order.id} />
        </FormItem>

        <FormItem label="Date">
          <Input type="text" disabled value={startDate} />
        </FormItem>

        <FormItem label="Driver">
          <Input type="text" disabled value={`${driverName}`} />
        </FormItem>

        <FormItem label="Helper">
          <Input type="text" disabled value={helperName} />
        </FormItem>

        <FormItem label="Driver Group">
          <Input type="text" disabled value={order.driverGroupName} />
        </FormItem>

        <FormItem label="Status">
          <Input type="text" disabled value={OrderStatusName[order.orderStatus]} />
        </FormItem>
      </div>

      <div className="grid grid-cols-1 gap-4 pb-5 lg:grid-cols-3">
        <FormItem label="Customer">
          <Input type="text" disabled value={customerName.trim() || '-'} />
        </FormItem>

        <FormItem label="Customer Email">
          <Input type="text" disabled value={order.customer?.user?.email ?? '-'} />
        </FormItem>

        <FormItem label="Customer Phone">
          <Input type="text" disabled value={order.customer?.user?.phoneNumber ?? '-'} />
        </FormItem>
      </div>

      <div className="grid grid-cols-1 gap-4 pb-5 lg:grid-cols-2">
        <FormItem label="Load Helpers">
          <Input type="text" disabled value={order.loadHelperCount ?? '-'} />
        </FormItem>
        <FormItem label="Unload Helpers">
          <Input type="text" disabled value={order.unloadHelperCount ?? '-'} />
        </FormItem>
      </div>

      {order.merchant && (
        <div className="grid grid-cols-1 gap-4 pb-5 lg:grid-cols-2">
          <FormItem label="Merchant">
            <Input type="text" disabled value={order.merchant.businessName} />
          </FormItem>

          <FormItem label="Merchant Order Number">
            <Input type="text" disabled value={order.merchantOrderNumber || '-'} />
          </FormItem>
        </div>
      )}
    </>
  );
};
